<template>
    <div>
        <h1>Bejegyzés hozzáadása</h1>

        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>

        <field class="form-group">
            <label>Kép:</label>
            <input type="file" name="file" ref="file" class="form-control" v-on:change="handleFileUpload()">
        </field>


        Szöveg:
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

        <p>
            <button class="btn btn-success mt-3" v-on:click="upload">Mentés</button>
        </p>
    </div>
</template>

<script>
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

    export default {
        name: "AddNews",
        data() {
            return {
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                    // The configuration of the editor.
                    height: 500
                },
                file: '',
                model: {
                    title: '',
                    slug: '',
                    content: '',
                },
                schema: {
                    fields: [
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Cím',
                            model: 'title',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Slug',
                            model: 'slug',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Meta cím (kb. 50 karakter)',
                            model: 'metaTitle',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Meta leírás (max 150 karakter)',
                            model: 'metaDescription',
                            required: true
                        },
                    ]
                },
                formOptions: {
                    validateAfterLoad: true,
                    validateAfterChanged: true,
                    validateAsync: true
                }
            }
        },
        methods: {
            upload() {
                const formData = new FormData();
                formData.append('title', this.model.title);
                formData.append('slug', this.model.slug)
                formData.append('content', this.editorData);
                formData.append('metaTitle', this.model.metaTitle);
                formData.append('metaDescription', this.model.metaDescription);
                formData.append('file', this.file);

                this.$http.post(`${process.env.VUE_APP_API_URL}/news`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    console.log(response.data)
                    this.$router.push({name: 'News'});
                })
            },
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
            },
        }
    }
</script>

<style>
    .ck-editor__editable {
        min-height: 500px;
    }
</style>
