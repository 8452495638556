import Vue from 'vue'
import VueRouter from 'vue-router'
import Image from '../views/Image'
import News from '../views/News'
import AddNews from "../views/AddNews";
import EditNews from "../views/EditNews";
import MetaInfoList from "@/views/MetaInfoList.vue";
import MetaInfoEdit from "@/views/MetaInfoEdit.vue";
import MetaInfoAdd from "@/views/MetaInfoAdd.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: News
    },
    {
        path: '/images',
        name: 'Image',
        component: Image
    },
    {
        path: '/news',
        name: 'News',
        component: News
    },
    {
        path: '/news/add',
        name: 'AddNews',
        component: AddNews
    },
    {
        path: '/news/edit/:id',
        name: 'EditNews',
        component: EditNews
    },
    {
        path: '/metas',
        name: 'MetaInfoList',
        component: MetaInfoList
    },
    {
        path: '/metas/edit/:id',
        name: 'MetaInfoEdit',
        component: MetaInfoEdit
    },
    {
        path: '/metas/add',
        name: 'MetaInfoAdd',
        component: MetaInfoAdd
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (router.app.$keycloak.authenticated) {
        next()
    } else {
        const loginUrl = router.app.$keycloak.createLoginUrl()
        window.location.replace(loginUrl);
    }
})



export default router
