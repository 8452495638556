<template>
    <div>
        <h1>MetaInfo hozzáadása</h1>

        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>

        Szöveg:
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

        <p>
            <button class="btn btn-success mt-3" v-on:click="upload">Mentés</button>
        </p>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: "MetaInfoAdd",
    data() {
        return {
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
                height: 500
            },
            file: '',
            model: {
                domain: '',
                metaType: '',
                metaValue: '',
                metaId: '',
            },
            schema: {
                fields: [
                    {
                        type: 'input',
                        inputType: 'text',
                        label: 'Domain',
                        model: 'domain',
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: 'Meta Type',
                        model: 'metaType',
                        required: true
                    },
                    {
                        type: 'input',
                        inputType: 'text',
                        label: 'Meta Id',
                        model: 'metaId',
                        required: false
                    },
                ]
            },
            formOptions: {
                validateAfterLoad: true,
                validateAfterChanged: true,
                validateAsync: true
            }
        }
    },
    methods: {
        upload() {
            const formData = new FormData();
            formData.append('domain', this.model.domain);
            formData.append('metaType', this.model.metaType)
            formData.append('metaValue', this.editorData);
            formData.append('metaId', this.model.metaId);

            this.$http.post(`${process.env.VUE_APP_API_URL}/metainfo`, formData)
                .then(response => {
                  console.log(response.data)
                  this.$router.push({name: 'MetaInfoList'});
            })
        },
    }
}
</script>
