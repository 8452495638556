<template>
    <div>
        <h1>Hirek</h1>
        <p>
            <router-link :to="{name: 'AddNews'}">Új hír hozzáadása</router-link>
        </p>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Kép</th>
                    <th scope="col">Cim</th>
                    <th scope="col">Műveletek</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="n in news" :key="n.id">
                    <td scope="row">
                        {{ n.id }}
                    </td>
                    <td>
                        <img v-if="n.images.length > 0" :src="`data:image/jpg;base64,${n.images[0].picture}`" :alt="n.title" style="width: 100px">
                        <span v-else>Nincs kép</span>
                    </td>
                    <td>
                        {{ n.title }}
                    </td>
                    <td>
                        <router-link class="btn btn-sm btn-info m-1" tag="button" :to="{name: 'EditNews', params: {'id': n.id}}">Szerkeszt</router-link>
                        <button class="btn btn-sm btn-danger m-1"  @click="deleteNews(n.id)">Töröl</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-text="Első"
                prev-text="Előző"
                next-text="Következő"
                last-text="Utolsó"
                align="center"
        ></b-pagination>


    </div>
</template>

<script>
    export default {
        name: "News",
        data() {
            return {
                api_url: process.env.VUE_APP_API_URL,
                news: [],
                currentPage: 1,
                perPage: 50,
                totalItems: 0,
            }
        },
        methods: {
            fetchData() {
                this.$http.get(`${this.api_url}/news?page=${this.currentPage - 1}&size=${this.perPage}`).then(response => {
                    this.news = response.data.content
                    this.totalItems = response.data.totalElements;
                })
            },
            deleteNews(id) {
                this.$bvModal.msgBoxConfirm('Biztos a hír törlésében?', {
                    title: 'Törlés',
                    okVariant: 'danger',
                    okTitle: 'Törlés',
                    cancelVariant: 'dark',
                    cancelTitle: 'Mégsem'

                })
                    .then(value => {
                        if (value) {
                            this.$http.delete(`${process.env.VUE_APP_API_URL}/news/${id}`)
                                .then(() => {
                                    this.$router.push({name: 'Home'})
                                }).catch(message => {
                                this.$bvModal.msgBoxOk("Hiba a törlés közben: " + message)
                            })

                        }
                    })

            }
        },
        created() {
            this.fetchData()
        },
        watch: {
            currentPage: {
                handler: function () {
                    this.fetchData().catch(error => {
                        console.error(error);
                    })
                }
            }

        }
    }
</script>
