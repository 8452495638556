<template>
    <div id="app">

        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <a class="navbar-brand" href="#">News Admin</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <router-link class="nav-link" to="/">Kezdőlap <span class="sr-only">(current)</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/news">Hirek</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/images">Képek</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/metas">Meta</router-link>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="container-fluid mt-5">
            <router-view/>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'app',
    }
</script>

<style>
    .selector-for-some-widget {
        box-sizing: content-box;
    }
</style>
