<template>
    <div>
        <h1>Bejegyzés szerkesztése</h1>

        <vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>

        Képek:
        <div  v-if="images.length > 0">
        <p v-for="image in images" :key="image.id">
            <img :src="`data:image/jpg;base64,${image.picture}`" class="img-thumbnail" style="width: 200px" alt="Demo"><br />
            <button class="btn btn-sm btn-danger m-1"  @click="deletePicture(image.id)">Töröl</button>
        </p>
        </div>
        <div v-else>
            <field class="form-group">
                <label>Kép:</label>
                <input type="file" name="file" ref="file" class="form-control" v-on:change="handleFileUpload()">
            </field>
            <button class="btn btn-success" @click="uploadPicture">Kép feltöltése</button>
        </div>

        Szöveg:
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

        <p>
            <button class="btn btn-success mt-3" v-on:click="upload">Mentés</button>
        </p>
    </div>
</template>

<script>
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

    export default {
        name: "EditNews",
        data() {
            return {
                images: [],
                editor: ClassicEditor,
                editorData: '',
                editorConfig: {
                    // The configuration of the editor.
                    height: 500
                },
                file: '',
                model: {
                    title: '',
                    slug: '',
                    content: '',
                    metaTitle: '',
                    metaDescription: '',
                },
                schema: {
                    fields: [
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Cím',
                            model: 'title',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Slug',
                            model: 'slug',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Meta cím (kb. 50 karakter)',
                            model: 'metaTitle',
                            required: true
                        },
                        {
                            type: 'input',
                            inputType: 'text',
                            label: 'Meta leírás (kb. 150 karakter)',
                            model: 'metaDescription',
                            required: true
                        },
                    ]
                },
                formOptions: {
                    validateAfterLoad: true,
                    validateAfterChanged: true,
                    validateAsync: true
                }
            }
        },
        created() {
            this.fetchData()
        },
        methods: {
            fetchData() {
              this.$http.get(`${process.env.VUE_APP_API_URL}/news/${this.$route.params.id}`).then(response => {
                  this.model.title = response.data.title
                  this.model.slug = response.data.slug
                  this.editorData = response.data.content
                  this.model.metaTitle = response.data.metaTitle
                  this.model.metaDescription = response.data.metaDescription
                  this.images = response.data.images
              })
            },
            upload() {
                this.model.content = this.editorData
                this.$http.put(`${process.env.VUE_APP_API_URL}/news/${this.$route.params.id}`, this.model).then(response => {
                    console.log(response.data)
                    this.$router.push({name: 'News'});
                })
            },
            deletePicture(id) {
                this.$bvModal.msgBoxConfirm('Biztos a kép törlésében?', {
                    title: 'Törlés',
                    okVariant: 'danger',
                    okTitle: 'Törlés',
                    cancelVariant: 'dark',
                    cancelTitle: 'Mégsem'

                })
                    .then(value => {
                        if (value) {
                            this.$http.delete(`${process.env.VUE_APP_API_URL}/images/${id}`)
                                .then(() => {
                                    //this.$router.push({name: 'News'})
                                    this.fetchData()
                                }).catch(message => {
                                this.$bvModal.msgBoxOk("Hiba a törlés közben: " + message)
                            })

                        }
                    })
            },
            uploadPicture() {
                const formData = new FormData();
                formData.append('file', this.file);
                this.$http.post(`${process.env.VUE_APP_API_URL}/news/${this.$route.params.id}/images`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        console.log(response);
                        this.fetchData();
                    })
            },
            handleFileUpload(){
                this.file = this.$refs.file.files[0];
            },
        },
        watch: {
            images: {
                handler: function () {
                    console.log("Updated");
                }
            }
        }
    }
</script>

<style scoped>

</style>
