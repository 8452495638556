import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFormGenerator from 'vue-form-generator'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

import 'vue-form-generator/dist/vfg.css'
import './custom.scss'

Vue.config.productionTip = false

Vue.use(CKEditor)
Vue.use(VueAxios, axios)
Vue.use(VueFormGenerator)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'check-sso'
  },
  onReady: () => {
    tokenInterceptor();
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  },
  config: {
    authRealm: process.env.VUE_APP_KEYCLOAK_REALM,
    authUrl: process.env.VUE_APP_KEYCLOAK_URL,
    authClientId: process.env.VUE_APP_CLIENT_ID,
    logoutRedirectUri: 'https://admin.budapest-escort-girl.eu/'
  }
});


function tokenInterceptor() {
  axios.interceptors.request.use(config => {
    if (Vue.prototype.$keycloak.token != null) {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    }
    return config
  }, error => {
    return Promise.reject(error)
  })
}
