<template>
    <div>
        <h1>Meta Info</h1>
        <p>
            <router-link :to="{name: 'MetaInfoAdd'}">Új hozzáadása</router-link>
        </p>
        <table class="table">
            <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Domain</th>
                <th scope="col">Meta Típus</th>
                <th scope="col">Műveletek</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="m in metas" :key="m.id">
                <td scope="row">
                    {{ m.id }}
                </td>
                <td>
                    {{ m.domain }}
                </td>
                <td>
                    {{ m.metaType }}
                </td>
                <td>
                    <router-link class="btn btn-sm btn-info m-1" tag="button" :to="{name: 'MetaInfoEdit', params: {'id': m.id}}">Szerkeszt</router-link>
                    <button class="btn btn-sm btn-danger m-1" @click="deleteMetaInfo(m.id)">Töröl</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "MetaInfoList",
    data() {
        return {
            api_url: process.env.VUE_APP_API_URL,
            metas: []
        }
    },
    methods: {
        fetchData() {
            this.$http.get(`${this.api_url}/metainfo`).then(response => {
                this.metas = response.data
            })
        },
        deleteMetaInfo(id) {
            this.$bvModal.msgBoxConfirm('Biztos a törlésében?', {
                title: 'Törlés',
                okVariant: 'danger',
                okTitle: 'Törlés',
                cancelVariant: 'dark',
                cancelTitle: 'Mégsem'
            })
                .then(value => {
                    if (value) {
                        this.$http.delete(`${process.env.VUE_APP_API_URL}/metainfo/${id}`)
                            .then(() => {
                                this.$router.push({name: 'MetaInfoList'})
                            }).catch(message => {
                            this.$bvModal.msgBoxOk("Hiba a törlés közben: " + message)
                        })

                    }
                })

        }
    },
    created() {
        this.fetchData()
    },
    watch: {
        currentPage: {
            handler: function () {
                this.fetchData().catch(error => {
                    console.error(error);
                })
            }
        }

    }
}
</script>
