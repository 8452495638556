<template>
    <div>
        <h1>Képek</h1>

        <p v-for="image in images" :key="image.id">
            <img :src="`data:image/jpg;base64,${image.picture}`" class="img-thumbnail" alt="Demo">
        </p>
    </div>
</template>

<script>
    export default {
        name: "Image",
        data() {
            return {
                images: [],
            }
        },
        created() {
            //console.log("Loaded...")
            this.$http.get(`${process.env.VUE_APP_API_URL}/images`)
            .then(response => {
                this.images = response.data.content;
                console.log(response.data.content)
            })
        }
    }
</script>

<style scoped>

</style>
