<template>
    <div>
        <h1>Meta Info szerkesztése</h1>

        Tartalom:
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

        <p>
            <button class="btn btn-success mt-3" v-on:click="upload">Mentés</button>
        </p>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    name: "MetaInfoEdit",
    data() {
        return {
            images: [],
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                // The configuration of the editor.
                height: 500
            },
            file: '',
            model: {
                metaValue: '',
            },
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$http.get(`${process.env.VUE_APP_API_URL}/metainfo/${this.$route.params.id}`).then(response => {
                this.model.metaValue = response.data.metaValue
                this.editorData = response.data.metaValue
            })
        },
        upload() {
            this.model.metaValue = this.editorData
            this.$http.put(`${process.env.VUE_APP_API_URL}/metainfo/${this.$route.params.id}`, this.model).then(response => {
                console.log(response.data)
                this.$router.push({name: 'MetaInfoList'});
            })
        },
    }
}
</script>

<style scoped>

</style>